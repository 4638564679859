button {
  background: #2f4858;
  color: #fff;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none 0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

input[type='text'],
input[type='email'] {
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

input[type='text']:focus,
input[type='email']:focus {
  border-color: rgba(0, 0, 0, 0.5);
  outline: 0 none;
}

.error {
  color: #800;
}

h2 a {
  color: inherit;
  text-decoration: underline;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.photo-wrapper {
  position: relative;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.arrow-dn {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

tr:last-child th,
tr:last-child td {
  border-bottom: 0;
}

@media screen and (min-width: 768px) {
  .photo-wrapper {
    max-height: 600px;
  }
}
